import React          from 'react';
import { graphql }    from 'gatsby';
import { GiMetalBar } from 'react-icons/gi';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useTheme
}                     from '@chakra-ui/react';

import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';

const MaterialkundePage = (props) => {
  const material = props.data.material.nodes;
  const theme = useTheme();
  const color = theme.colorMode === 'light' ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)';
  return (
    <Wrapper>
      <Spacer/>
      <Heading icon={<GiMetalBar/>} subtitle="Ihre Fragen von uns beantwortet">Materialkunde</Heading>
      <Accordion allowToggle>
        {material.map(item => {
          return (
            <AccordionItem sx={{
              h2: {
                margin: '10px 0'
              },
              svg: {
                color: color
              }
            }}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <b>{item.title}</b>
                  </Box>
                  <AccordionIcon/>
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <div dangerouslySetInnerHTML={{ __html: item.content }}/>
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
      <Spacer/>
      <CallToAction description="Vereinbaren Sie noch heute Ihren Termin zur Trauringberatung"
                    button="Zur Terminvereinbarung" link="/terminvereinbarung" imageFileTitle="theme-sonora-call-to-action-background-trauringe"/>
      <Spacer/>
    </Wrapper>
  )
};

export default MaterialkundePage;

export const query = graphql`
    query {
        material: allDirectusWeddingRingMaterials(filter: {id: {ne: "dummy"}}) {
            nodes {
                title
                status
                content
            }
        }
    }
`;
